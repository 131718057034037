/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
// @mui
import { TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router';
// components
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies

// ----------------------------------------------------------------------

CustomerRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
};

export default function CustomerRow({ row, selected }) {
  const {
    name,
    phoneNumber,
    storeName,
    address,
    email,
    extraInfo,
    outstanding,
    customerTypeId,
    customerType,
    id,
  } = row;
  const navigate = useNavigate();

  return (
    <TableRow hover selected={selected}>
      <TableCell
        align="left"
        variant="subtitle2"
        sx={{ cursor: 'pointer' }}
        onClick={() => navigate(`/dashboard/customer/${id}`)}
      >
        {name}
      </TableCell>

      <TableCell align="left" variant="subtitle2" sx={{ cursor: 'pointer' }}>
        {phoneNumber}
      </TableCell>

      <TableCell align="left">{email || '-'}</TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {customerType?.name || '-'}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {storeName || '-'}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {outstanding && outstanding !== 'undefined' ? `₹ ${outstanding}` : '₹ 0'}
      </TableCell>
    </TableRow>
  );
}
