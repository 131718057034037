import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// @mui
import {
  Box,
  Card,
  Container,
  Divider,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
// routes
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { TableHeadCustom, TableNoData, TableSkeleton } from 'src/components/table';
import useTable from 'src/hooks/useTable';
import useTabs from 'src/hooks/useTabs';
// import OutStandingRow from 'src/sections/tableRow/outstandingRow';
import SalesRow from 'src/sections/tableRow/salesRow';
import OutStandingToolbar from 'src/sections/toolbar/OutstandingToolbar';
import AxiosAll from 'src/service/axiosAll';
import { request } from 'src/service/request';
import { whitespace } from 'stylis';

// ----------------------------------------------------------------------

const TABLE_HEAD_ADMIN = [
  { id: 'sales', label: 'Sales Person', align: 'left' },
  { id: 'cName', label: 'Client Name', align: 'left' },
  { id: 'logs', label: 'Last Updated', align: 'left' },
  { id: 'item', label: 'Order Items', align: 'left' },
  { id: 'action', label: 'Quantity', align: 'left' },
  { id: 'amount', label: 'Amount', align: 'left' },
  { id: 'store', label: 'Store Name', align: 'left' },
  { id: 'add', label: 'Address', align: 'left' },
];

// ----------------------------------------------------------------------

export default function Orders() {
  const {
    dense,
    page,
    rowsPerPage,
    setPage,
    selected,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();
  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const user = useSelector((state) => state.user);

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [users, setUser] = useState();

  const location = useLocation();
  const [search, setSearch] = useSearchParams();
  const [userId, setUserId] = useState(search.get('user_id') || '');
  const [date, setDate] = useState(search.get('date') || '');
  const [query, setQuery] = useState('');

  const handleFilterName = () => {
    setPage(0);
  };

  const getUser = async () => {
    setUser([]);
    const response = await AxiosAll('get', 'users/dropdown', {}, user?.token);
    if (response?.status === 200) {
      setUser(response?.data?.data);
    }
  };

  const getSells = async () => {
    try {
      setLoading(true);
      const res = await request({
        method: 'get',
        url: `/sales/all-sales?limit=${rowsPerPage}&search=${query.trim()}&pages=${page + 1}`,
        token: user?.token,
      });
      setLoading(false);
      setTableData(res?.data);
      setCount(res?.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSells();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page]);

  const isNotFound = !tableData?.length && !!filterStatus;

  return (
    <Page title="Orders">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Sales List"
          links={[{ name: 'Dashboard' }, { name: 'Sales List' }]}
        />

        <Stack
          spacing={2}
          className="stack-center"
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ px: 0.5, py: 1.5 }}
        />

        <Card>
          <OutStandingToolbar
            onFilterName={handleFilterName}
            setQuery={setQuery}
            placeholder="Search Client"
          />
          <Divider sx={{ mt: 1, mb: 2 }} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  hideCheckbox
                  headLabel={TABLE_HEAD_ADMIN}
                  rowCount={tableData?.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData?.map((row) => row.id)
                    )
                  }
                />
                {loading ? (
                  <>
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                  </>
                ) : (
                  <TableBody>
                    {tableData?.map((row) => (
                      <SalesRow key={row.id} row={row} getOutstanding={getSells} />
                    ))}
                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
