import { useEffect, useState } from 'react';
// @mui
import {
  Box,
  Card,
  Container,
  Divider,
  FormControlLabel,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TextField,
} from '@mui/material';
// routes
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { TableHeadCustom, TableNoData, TableSkeleton } from 'src/components/table';
import useTable from 'src/hooks/useTable';
import useTabs from 'src/hooks/useTabs';
// import CallLogRow from 'src/sections/tableRow/callLogsRow';
import CustomerRow from 'src/sections/tableRow/customerRow';
import UserTableToolbar from 'src/sections/toolbar/UserToolbar';
import AxiosAll from 'src/service/axiosAll';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const TABLE_HEAD_ADMIN = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'phoneNumber', label: 'Phone Number', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'customerTypeId', label: 'Customer Type', align: 'left' },
  { id: 'storeNumber', label: 'Store Number', align: 'left' },
  { id: 'outstanding', label: 'Outstanding', align: 'left' },
];
// ----------------------------------------------------------------------

export default function Customer() {
  const {
    dense,
    page,
    rowsPerPage,
    setPage,
    selected,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettingsContext();
  const [tableData, setTableData] = useState([]);
  const user = useSelector((state) => state.user);
  const userdetail = useSelector((state) => state.user.user);
  const [query, setQuery] = useState();
  const { currentTab: filterStatus } = useTabs('all');
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [users, setUser] = useState();
  const [userId, setUserId] = useState('');

  const handleFilterName = () => {
    setPage(0);
  };

  const getCustomers = async () => {
    setLoading(true);
    const response = await AxiosAll(
      'get',
      `customers/team?limit=10&page=${page + 1}&search=${query || ''}&managerId=${userdetail.id}
       &userId=${userId}`,
      // TODO: Make Manager Id Dynamic
      // `customers/team?limit=10&page=${page+1}&search=${query}&managerId=111&userId=${userId}`,
      {},
      user?.token
    );
    const { data } = response;

    if (data?.statusCode === 200) {
      setLoading(false);
      setTableData(data?.data);
      setCount(data?.count);
    }
  };
  const getUser = async () => {
    setUser([]);
    const response = await AxiosAll(
      'get',
      `users/manager-team?managerId=${userdetail.id}`,
      {},
      user?.token
    );
    if (response?.status === 200) {
      setUser(response?.data?.data);
    }
  };

  useEffect(() => {
    getCustomers();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, query, userId]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isNotFound = !tableData?.length || (!tableData?.length && !!filterStatus);

  const handleUsers = (e) => {
    setUserId(e.target.value);
    setPage(0);
  };

  const ClearFilter = () => {
    setUserId('');
  };

  return (
    <Page title="Cutomers">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Customers List"
          links={[{ name: 'Dashboard' }, { name: 'Customers List' }]}
        />
        <Stack
          spacing={2}
          className="stack-center"
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ py: 0.5, px: 0.5 }}
        >
          <TextField
            fullWidth
            select
            label="User"
            value={userId}
            onChange={handleUsers}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            <MenuItem
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
              key=""
              value=""
            >
              Select
            </MenuItem>
            {users?.map((option) => (
              <MenuItem
                key={option?.user.id}
                value={option?.user.id}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option?.user.firstName} {option?.user.lastName}
              </MenuItem>
            ))}
          </TextField>

          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ backgroundColor: '#0351AB' }}
            onClick={() => ClearFilter()}
          >
            Clear
          </LoadingButton>
        </Stack>{' '}
        <Card  sx={{p:4}}>
          <UserTableToolbar
            title="Search Customer"
            onFilterName={handleFilterName}
            setQuery={setQuery}
          />
          <Divider  sx={{mt:1, mb:2}}/>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  hideCheckbox
                  headLabel={TABLE_HEAD_ADMIN}
                  rowCount={tableData?.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData?.map((row) => row.id)
                    )
                  }
                />
                {loading ? (
                  <>
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                  </>
                ) : (
                  <TableBody>
                    {tableData?.map((row) => (
                      <CustomerRow key={row.id} row={row} getCustomers={getCustomers} />
                    ))}

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
