import packageEnv from '../package.json';
import { ProdServer, stagingServer } from './Sever';
// main server controller
const EnvType = (Env) => {
  switch (Env) {
    case 1:
      return stagingServer;
    case 2:
      return ProdServer;
    default:
      return stagingServer;
  }
};
export default {
  currentEnv: EnvType(packageEnv?.env),
};
